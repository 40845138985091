var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('PLANNING_ADMIN:admin') || _vm.$can('PLANNING_SUPERADMIN:admin'))?_c('b-sidebar',{attrs:{"id":"add-new-simulation-sidebar","visible":_vm.isAddNewSimulationSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-simulation-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ajouter une nouvelle simulation")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"createFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"libellé de la nouvelle simulation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Nom de la simulation"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Nom..."},model:{value:(_vm.newSimulation.nom),callback:function ($$v) {_vm.$set(_vm.newSimulation, "nom", $$v)},expression:"newSimulation.nom"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-group',[_c('label',{attrs:{"for":"commentaire"}},[_vm._v("Commentaire")]),_c('b-form-textarea',{attrs:{"id":"commentaire","placeholder":"Commentaire..."},model:{value:(_vm.newSimulation.commentaire),callback:function ($$v) {_vm.$set(_vm.newSimulation, "commentaire", $$v)},expression:"newSimulation.commentaire"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"copyCreneaux"}},[_vm._v(" Copie des créneaux "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"name":"check-button","switch":""},model:{value:(_vm.newSimulation.isCopyFromUsers),callback:function ($$v) {_vm.$set(_vm.newSimulation, "isCopyFromUsers", $$v)},expression:"newSimulation.isCopyFromUsers"}})],1),(_vm.newSimulation.isCopyFromUsers)?_c('div',[_c('validation-provider',{attrs:{"name":"Salarié(s)","rules":{required: _vm.newSimulation.isCopyFromUsers}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"salaries"}},[_vm._v(" Salarié(s) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('searchable-vue-select',{attrs:{"id":"salaries","multiple":"","label":"completeLabel","placeholder":"Choisissez un ou plusieurs salarié(s)","options":_vm.listSalaries.items,"hasNextPage":_vm.listSalaries.items.length <
                  _vm.listSalaries.totalCount,"loadOnCreate":true,"reduce":function (option) { return option.id; }},on:{"load-next-page":function (params) { return _vm.searchSalaries(params); }},model:{value:(_vm.newSimulation.usersCopyIds),callback:function ($$v) {_vm.$set(_vm.newSimulation, "usersCopyIds", $$v)},expression:"newSimulation.usersCopyIds"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"startDateCopy","rules":{required: _vm.newSimulation.isCopyFromUsers}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"startDateCopy"}},[_vm._v(" Période du "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{attrs:{"id":"startDateCopy","placeholder":"Pas de date choisie"},model:{value:(_vm.newSimulation.startDateCopy),callback:function ($$v) {_vm.$set(_vm.newSimulation, "startDateCopy", $$v)},expression:"newSimulation.startDateCopy"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"endDateCopy","rules":{required: _vm.newSimulation.isCopyFromUsers}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"endDateCopy"}},[_vm._v(" Fin le "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{attrs:{"id":"endDateCopy","placeholder":"Pas de date choisie"},model:{value:(_vm.newSimulation.endDateCopy),callback:function ($$v) {_vm.$set(_vm.newSimulation, "endDateCopy", $$v)},expression:"newSimulation.endDateCopy"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1):_vm._e()],1)]}}],null,true)})]}}],null,false,538961768)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }