







































































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormTextarea,
  BFormDatepicker
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { SimulationCreateModel } from "@/api/models/creneaux/creneau";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { PaginatedList } from "@/api/models/common/paginatedList";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BFormTextarea,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "create-simulation",
})
export default class CreateSimulation extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewSimulationSidebarActive!: boolean;
  loading = false;

  required = required;

  listSalaries = new PaginatedList<DetailsUtilisateur>();

  newSimulation: SimulationCreateModel = {
    isCopyFromUsers: false
  };

  resetForm() {
    this.newSimulation = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.simulations
      .post(this.newSimulation)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'une simulation",
            text: "Type de créneau créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-simulations");
        this.$emit("update:is-add-new-simulation-sidebar-active", false);
        this.loading = false;
      });
  }

  async searchSalaries(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalaries.items = this.listSalaries!.items.concat(
            response.items.filter(
              (ri) => !this.listSalaries!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalaries.hasNextPage = response.hasNextPage
        this.listSalaries.hasPreviousPage = response.hasPreviousPage
        this.listSalaries.pageIndex = response.pageIndex
        this.listSalaries.totalCount = response.totalCount
        this.listSalaries.totalPages = response.totalPages
      });
  }
}
